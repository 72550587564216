import React from 'react'
import './MobileSocial.css'
import DisThin from '../../Images/m-dis.png'
import InstaThin from '../../Images/m-insta.png'
import TwiThin from '../../Images/m-twitter.png'
import GitThin from '../../Images/m-git.png'
import Mail from '../../Images/Mail.png'
import alilogo from '../../Images/alilogo.png'
import MobileContent from '../../components/MobileContent/MobileContent'

function MobileSocial() {
    const openLink = (url) => {
        window.open(url, '_blank');
    };

  return (
    <>
        <div className="m--title ms">
            <p>Choose One ...</p>
        </div>
        <div className="m-socialmedia ms">
            <p className='ms--title'><span>///</span> Socialmedia</p>
            <MobileContent icon={InstaThin} title="Instagram" onClick={() => openLink('https://www.instagram.com/alijenesyx/')} />
            <MobileContent icon={DisThin} title="Discord" onClick={() => openLink('https://discord.gg/6w7ADu7bsB')} />
            <MobileContent icon={TwiThin} title="Twitter" onClick={() => openLink('https://twitter.com/Jenesyx')} />
            <MobileContent icon={GitThin} title="Github" onClick={() => openLink('https://github.com/Jenesyx')} />
        </div>
        <div className="m-contact ms">
            <p className='ms--title'><span>///</span> Contact Me</p>
            <MobileContent icon={Mail} title="Email" onClick={() => openLink('mailto:a.bidkhori2004@gmail.com')} />
        </div>
        <div className="m-position ms">
            <p className='ms--title'><span>///</span> Current Position</p>
            <p className='m-desc'>I'm available for work in <span>UI/UX design</span>, <span>Front-end development</span>, and <span>Graphic/Logo design</span>.</p>
        </div>
        <div className="m-logo">
            <img src={alilogo} alt="ali-logo" />
        </div>
    </>
  )
}

export default MobileSocial