import React from 'react'
import './MobileContent.css'

function MobileContent(props) {
  return (
    <>
        <div className='m-content--holder' onClick={props.onClick}>
            <div className="m-icon--holder">
                <div className='m-icon'>
                    <img src={props.icon} alt="icon" />
                </div>
            </div>
            <div className='m-title'>
                <p>{props.title}</p>
            </div>
        </div>
    </>
  )
}

export default MobileContent