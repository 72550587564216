import React from 'react'
import './GG.css'
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer'
import sound1 from '../../Audios/1137795634078941376.mp3'

function GG() {

  return (
    <>
        <div className='gg'>
            <p>GG</p>
            <div className="hide-this">
                <AudioPlayer src={sound1}/>
            </div>
        </div>
    </>
  )
}

export default GG