import React from 'react'
import './Working.css'
import { useNavigate } from 'react-router-dom';


function Working() {
    const navigate = useNavigate();

    const toHome = () => {
        navigate('/')
    }

    return (
    <>
        <div className="wball1"></div>
        <div className="wball2"></div>
        <div className='working-h1'>Working on it ...</div>
        <div onClick={toHome} className="backtohome">Back to home</div>
    </>
)}

export default Working