import React, { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './ProductCard.css'
import { motion } from "framer-motion";

function ProductCard(props) {

    const cardRef = useRef(null);
    const navigate = useNavigate()

    const toProduct = () => {
        navigate(`/${props.page}/${props.Id}`);
    }

    const handleMouseMove = (e) => {
        const card = cardRef.current;
        if (!card) return;

        const { left, top, width, height } = card.getBoundingClientRect();
        const centerX = left + width / 2;
        const centerY = top + height / 2;
        const mouseX = e.clientX - centerX;
        const mouseY = e.clientY - centerY;
        const rotateX = mouseY / 10;
        const rotateY = mouseX / 10; 

        card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = () => {
        const card = cardRef.current;
        if (card) {
        card.style.transform = 'rotateX(0) rotateY(0)';
        }
    };

    useEffect(() => {
        const card = cardRef.current;
        card.addEventListener('mousemove', handleMouseMove);
        card.addEventListener('mouseleave', handleMouseLeave);

        return () => {
        card.removeEventListener('mousemove', handleMouseMove);
        card.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <motion.div className='pc' ref={cardRef} onClick={toProduct}
            initial={{opacity: 0, y: 60}}
            whileInView={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
            viewport={{once: true}}
        >
            <div className="pc-img-holder">
                <img src={props.Image} alt="item" />
            </div>
            <div className='pc-info'>
                <p>{props.Title}</p>
                <p>{props.Desc}</p>
            </div>
            <div className="price-btn">
                <div className='pc-price'>
                    <p>from</p>
                    <p>{props.Price}$</p>
                </div>
                <div className="pc-btn">Buy</div>
            </div>
        </motion.div>
    )
}

export default ProductCard