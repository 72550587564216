import React from 'react'
import './Loading.css'
import Spline from '@splinetool/react-spline'

function Loading() {
  return (
    <>
        <div className="sliders-holder">
            <div className="loader-holder">
              <span class="loader"></span>
            </div>
            <div className="slide-left"></div>
            <div className="slide-right"></div>
        </div>
    </>
  )
}

export default Loading