import React from 'react'
import './SkillsPage.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import SkillBox from '../../components/SkillBox/SkillBox'
import SkillE11 from '../../Images/SkillE1-13.png'
import SkillE12 from '../../Images/SkillE1-23.png'
import SkillE13 from '../../Images/SkillE1-33.png'
import SkillE21 from '../../Images/SkillE2-13.png'
import SkillE22 from '../../Images/SkillE2-23.png'
import SkillE23 from '../../Images/SkillE2-33.png'
import SkillE31 from '../../Images/SkillE3-13.png'
import SkillE32 from '../../Images/SkillE3-23.png'
import SkillE33 from '../../Images/SkillE3-33.png'
import SkillE41 from '../../Images/SkillE4-13.png'
import SkillE42 from '../../Images/SkillE4-23.png'
import SkillE43 from '../../Images/SkillE4-33.png'
import SkillE51 from '../../Images/SkillE5-13.png'
import SkillE52 from '../../Images/SkillE5-23.png'
import SkillE53 from '../../Images/SkillE5-33.png'
import SkillE61 from '../../Images/SkillE6-13.png'
import SkillE62 from '../../Images/SkillE6-23.png'
import SkillE63 from '../../Images/SkillE6-33.png'
import SkillE71 from '../../Images/SkillE7-13.png'
import SkillE72 from '../../Images/SkillE7-23.png'
import SkillE73 from '../../Images/SkillE7-33.png'
import SkillE81 from '../../Images/SkillE8-13.png'
import SkillE82 from '../../Images/SkillE8-23.png'
import SkillE83 from '../../Images/SkillE8-33.png'
import SkillE91 from '../../Images/SkillE9-13.png'
import SkillE92 from '../../Images/SkillE9-23.png'
import SkillE93 from '../../Images/SkillE9-33.png'
import SkillE101 from '../../Images/SkillE10-13.png'
import SkillE102 from '../../Images/SkillE10-23.png'
import SkillE103 from '../../Images/SkillE10-33.png'
import spPolygon1 from '../../Images/p-polygon1.png'
import spPolygon2 from '../../Images/p-polygon2.png'
import spPolygon3 from '../../Images/p-polygon3.png'
import spPolygon4 from '../../Images/p-polygon4.png'
import MobileFooter from '../../components/MobileFooter/MobileFooter'


function SkillPage() {
  return (
    <div className='Skills' id='Skills'>
      <>
        <div className='Header--controller'>
            <Header showWelcomeMessage = {false}/>
        </div>
        <div className="clear"></div>
        <div className="sp-bg sp-polygon1"><img src={spPolygon1} alt="polygon1" /></div>
        <div className="sp-bg sp-polygon2"><img src={spPolygon2} alt="polygon2" /></div>
        <div className="sp-bg sp-polygon3"><img src={spPolygon3} alt="polygon3" /></div>
        <div className="sp-bg sp-polygon4"><img src={spPolygon4} alt="polygon4" /></div>
        <SkillBox title='AK Logo' img1={SkillE11} img2={SkillE12} img3={SkillE13}
          text="I created a distinctive logo for Amir Koopayeh by ingeniously blending the initials of his first name and lastname. Leveraging the Farsi meaning of 'Koopayeh' as foothills, I incorporated this concept into the design, resulting in a truly harmonious fusion. Additionally, I meticulously crafted a sophisticated business card, strategically employing a palette of gold and gray hues. These refined tones were chosen purposefully, aligning with Amir's role as an esteemed international business coach. The deliberate choice of colors lends an air of professionalism, effectively capturing the attention of potential clients. The logo's memorability factor is maximized, ensuring Amir's identity becomes indelibly etched in the minds of all who encounter it." 
        />
        <SkillBox title='Woman Life Freedom' img1={SkillE101} img2={SkillE102} img3={SkillE103}
          text="This is the famous logo of Zan Zendegi Azadi, which the whole world has seen once, and it is one of my designs that became very famous. Woman, life, freedom, are written inside each other in Persian language."
        />
        <SkillBox title='Shokr Cover Art' img1={SkillE71} img2={SkillE72} img3={SkillE73}
          text="The creation of the cover art for Shokr music by Seniar was a deliberate process, drawing inspiration from both the music and photography. The selected scene from the video was chosen with careful consideration, influenced by the genre and thematic elements of the music. The relationship between the music's essence and the visual representation played a crucial role in shaping the cover art. Every element in the artwork was thoughtfully curated to encapsulate the mood, tone, and narrative of the music, ensuring a seamless fusion of auditory and visual experiences. This artistic endeavor serves as a testament to the interconnectedness of music and imagery, highlighting the impactful role of visual storytelling in enhancing artistic expression. The cover art stands as a harmonious reflection of the musical genre, creating a compelling visual narrative that complements the Shokr music experience."
        />
        <SkillBox title='Valorant Boosting' img1={SkillE21} img2={SkillE22} img3={SkillE23}
          text="I recently designed a website for a friend of mine who is a highly skilled player in Valorant. The website offers a user-friendly platform for players to easily place orders for account boosting services or purchase Valorant accounts with high rankings. With this website, players no longer have to waste time and effort on grinding for a higher rank. Instead, they can focus on enjoying the game and leave the work to the experts. The website is designed to be efficient and straightforward, so players can make their purchases with ease. Whether you're a casual or a competitive player, this website is perfect for anyone looking to elevate their gameplay experience. Join the community of satisfied customers and let us help you achieve your Valorant goals."
        />
        <SkillBox title='X2 Fitness App' img1={SkillE31} img2={SkillE32} img3={SkillE33}
          text="Introducing my first concept for a mobile app - a simple and user-friendly platform designed for sports enthusiasts. The app is themed with dark colors to evoke the feeling of being in a sports club at home. Users can register as either coaches or athletes and connect with each other seamlessly. What makes this app unique is its emphasis on user-generated content and community-driven features, similar to Instagram. It's the perfect platform to fuel your passion for sports and connect with like-minded individuals. Join us and be a part of something special!"
        />
        <SkillBox title='Foods App' img1={SkillE41} img2={SkillE42} img3={SkillE43}
          text="This section delves into the registration aspect of an online food ordering program, a concept that originated from my creative vision and was designed with a broad, universal application in mind. Initially conceptualized without a predetermined target audience, the onus of refining and executing this idea now rests squarely on my shoulders. As the architect of this innovative system, I am tasked not only with shaping its foundational structure but also with ensuring its seamless implementation. This initiative, born from a visionary perspective, underscores my commitment to creating a platform that transcends boundaries and caters to a diverse array of users. In navigating the intricate process of transforming an abstract idea into a tangible and user-friendly reality, I am both the creator and the executor, fully dedicated to the success and universality of this online food ordering experience."
        />
        <SkillBox title='Amir Face art' img1={SkillE51} img2={SkillE52} img3={SkillE53}
          text="This marked the inception of my journey into face design, and in a realm where individual design styles define one's identity, I endeavored to incorporate my unique approach. In the initial stages, it was a venture marked by experimentation, characterized by distinctive elements that set it apart. Remarkably, I successfully crafted this design within a mere 6 hours, employing various design techniques to enhance its visual appeal and render it more fitting for a profile picture. While I continue to engage in this artistic pursuit, my current iterations lean towards simplicity, reflecting an evolved and refined aesthetic sensibility. The evolution from my inaugural face design to the present showcases not only my growth in skill but also a commitment to cultivating a design style that is both personal and timeless."
        />
        <SkillBox title='Color Balance' img1={SkillE61} img2={SkillE62} img3={SkillE63}
          text="The focal image in the upper left is a snapshot from a music video. The artist sought a finely edited photo from this angle, prompting me to offer a selection. Three options were neatly laid out at the bottom, with an additional two on the right side for the artist's consideration. Each sample was carefully crafted, showcasing my proficiency in post-production editing. The aim was to provide a range of choices, ensuring the artist could select the perfect representation of their vision. This collaborative effort emphasized not just technical skills but a commitment to exceeding creative expectations. Through meticulous consideration of lighting, color grading, and composition, each option was tailored to convey the desired mood. The process aimed at empowering the artist with comprehensive options, reflecting my dedication to delivering beyond anticipated standards."
        />
        <SkillBox title='Gangam Cover Art' img1={SkillE81} img2={SkillE82} img3={SkillE83}
          text="This artwork serves as the cover for Seniar's Gangam music. Selecting the right scene from the video and determining the appropriate editing style posed a considerable challenge. After investing hours in designing and rigorous testing, I successfully reached a conclusion that left the client highly satisfied. The intricacies of choosing the scene and refining the editing process were integral to capturing the essence of the music, ensuring a visual representation that resonates with both the artist's vision and the audience. The meticulous design process underscores my commitment to delivering a cover art that not only meets but exceeds the client's expectations, resulting in a visually compelling representation of Gangam music."
        />
        <SkillBox title='1401 Cover Art' img1={SkillE91} img2={SkillE92} img3={SkillE93}
          text="In the midst of the 2022 Iranian revolution, Seniar composed a poignant piece to rally support for the people. Inspired by this powerful message, I took on the task of designing the cover art for the music. The original photo captured a moment from a demonstration within Iran, featuring a courageous individual bearing a pedestrian sign on their shoulder. My creative endeavor involved enhancing the impact of this powerful image, amplifying its significance in visual storytelling."
        />
        <div className="c--space"></div>
        <div className="mf">
          <MobileFooter/>
        </div>
        <Footer/>
    </>
    </div>
  )
}

export default SkillPage