import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    const playPromise = audioRef.current.play();

    // Handling autoplay policy
    if (playPromise !== undefined) {
      playPromise
        .then(() => {})
        .catch((error) => {
          console.error('Autoplay prevented:', error);
        });
    }
  }, []);

  return <audio ref={audioRef} src={src} autoPlay controls />;
};

export default AudioPlayer;
