import React, { useState, useEffect } from 'react'
import './Static.css'
import { motion } from "framer-motion";
import Spline from '@splinetool/react-spline';

function Static(props) {

  const [count, setCount] = useState(0);

  useEffect(() => {
    const target = parseInt(props.number, 10);
    const duration = 3000;
    let start = 0;
    const increment = target / (duration / 20);

    const timer = setInterval(() => {
      start += increment;
      if (start < target) {
        setCount(Math.ceil(start));
      } else {
        setCount(target);
        clearInterval(timer);
      }
    }, 20);

    return () => clearInterval(timer);
  }, [props.number]);

  return (
    <motion.div
      initial={{opacity: 0, y: 50}}
      whileInView={{opacity: 1, y: 0}}
      transition={{duration: 0.7}}
      viewport={{once: true}}
    >
        <div className='icon'>
          <Spline className='spline1' scene={props.scene} />
        </div>
        <div className='info--holder'>
            <h3>{count}</h3>
            <p>{props.categorie}</p>
        </div>
    </motion.div>
  )
}

export default Static