import Product1 from '../../src/Images/product1.jpg'
import Tshirt1 from '../../src/Images/BlackT-shirt1.png'
import Tshirt2 from '../../src/Images/WhiteT-Shirt1.png'
import Tshirt3 from '../../src/Images/WhiteT-shirt2.png'
import IroniTshirt1 from '../../src/Images/IroniTshirt1.png'
import IroniTshirt2 from '../../src/Images/IroniTshirt2.png'
import IroniTshirt3 from '../../src/Images/IroniTshirt3.png'

const productsData = {
    '1': {
        Id: '1',
        page: 'Product',
        Image: Product1,
        Title: 'Business RFID Card',
        Desc: 'Customizable',
        Price: '10',
        Functions: [
            {
                name: 'URL/URI (Iphone / Android) | Populer',
                desc: "This feature allows you to program the RFID card to direct a smartphone to open a specific URL or URI when scanned. This can be a webpage, an online video, a social media profile, or any web-based resource. It's incredibly versatile, serving various purposes from marketing, information sharing, to interactive experiences."
            },
            {
                name: 'Social networks (Iphone / Android)',
                desc: 'Link directly to social media profiles or pages, making it easy for others to follow, like, or interact with your social media presence with just a scan.'
            },
            {
                name: 'Search on Google and ... (Iphone / Android)',
                desc: 'Initiates a Google search or directs to specific results, helping share specific information or promote online content efficiently.'
            },
            {
                name: 'Mail (Iphone / Android)',
                desc: 'Automates the process of sending an email by pre-filling the recipient\'s address, subject, and even the body of the email.'
            },
            {
                name: 'Contact[Name, Phone, Website and ... ] (Android)',
                desc: 'Instantly shares contact information, such as name, phone numbers, and websites, which can be saved directly into the phone\'s contact list.'
            },
            {
                name: 'Phone Number (Iphone / Android)',
                desc: 'Enables a quick call function to a specified phone number, facilitating immediate voice communication.'
            },
            {
                name: 'SMS (Iphone / Android)',
                desc: 'Automatically populates an SMS\'s recipient and content, ready to send, simplifying the process of sending predefined messages.'
            },
            {
                name: 'FaceTime (Iphone)',
                desc: 'Starts a FaceTime video call with a predetermined Apple ID, offering a quick way to initiate visual communication.'
            },
            {
                name: 'FaceTime Audio (Iphone)',
                desc: 'Similar to FaceTime video, but initiates an audio-only call, providing a direct line for voice chats via Apple\'s FaceTime Audio service.'
            },
            {
                name: 'Location (Iphone / Android)',
                desc: 'Directs users to a specific geographical location, useful for events, business locations, or meeting points.'
            },
            {
                name: 'Address (Iphone / Android)',
                desc: 'Shares physical or postal address details, making it easier for people to find a place or send mail.'
            },
            {
                name: 'Bitcoin Address (Iphone / Android)',
                desc: 'Shares a Bitcoin wallet address for quick and easy transactions, facilitating cryptocurrency exchanges.'
            },
            {
                name: 'Wi-Fi Connection (Android)',
                desc: 'Automatically connects to a specified Wi-Fi network, bypassing the need to manually enter passwords and network details.'
            }
        ]
    },
    '2': {
        Id: '2',
        page: 'ClothesProduct',
        Image: Tshirt3,
        Image1: Tshirt1,
        Image2: Tshirt3,
        Image3: Tshirt2,
        Title: 'Infinity T-Shirt',
        Title1: 'Infinity',
        Title2: 'T-shirt',
        Info: "This is one of those t-shirts that you have to buy when you see it! This logo was designed by Alijenesyx with inspiration from the Persian calligraphy font along with Ali's own creativity, which depicts the hardships he went through in his life in every line of this logo. Everyone gives beauty to this t-shirt with their own personality and makes it unique. Although this logo is Ali himself, it got the name infinity because of drawing the twist of the world.",
        Desc: 'Special and Unique T-Shirt',
        Price: '25',
        ColorOptions: [
            { name: 'Black', hex: '#000000' },
            { name: 'White', hex: '#FFFFFF' }
        ],
        SizeOptions: ['XS', 'S', 'M', 'L', 'XL']
    },
    '3': {
        Id: '3',
        page: 'ClothesProduct',
        Image: IroniTshirt1,
        Image1: IroniTshirt3,
        Image2: IroniTshirt1,
        Image3: IroniTshirt2,
        Title: 'Ironi T-Shirt',
        Title1: 'Ironi',
        Title2: 'T-shirt',
        Info: 'It says Ironi! It means a legit Persian! So, you have to be e Persian!',
        Desc: 'Special and Unique T-Shirt',
        Price: '25',
        ColorOptions: [
            { name: 'Black', hex: '#000000' },
            { name: 'White', hex: '#FFFFFF' }
        ],
        SizeOptions: ['XS', 'S', 'M', 'L', 'XL']
    }
};

export default productsData;