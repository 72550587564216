import React from 'react'
import './SkillBox.css'
import { motion } from "framer-motion";

function SkillBox(props) {
  return (
    <>
        <motion.div className='SkillBox-Holder'
            initial={{opacity: 0, y: 70, scale: 0.8}}
            whileInView={{opacity: 1, y: 0, scale: 1}}
            transition={{duration: 0.6}}
            viewport={{once: true}}
        >
            <div className='Skill--holder'>
                <div className='box--left box'>
                    <h2>{props.title}</h2>
                    <p>{props.text}</p>
                </div>
                <div className='box--right box'>
                    <div className='box--1-2 primary-box'>
                        <div className='box--1'>
                            <div className='box-1'><img src={props.img1} alt="Image1" /></div>
                        </div>
                        <div className='box--2'>
                            <div className='box-2'><img src={props.img3} alt="Image3" /></div>
                        </div>
                    </div>3
                    <div className='box--3 primary-box'>
                        <div className='box-3'><img src={props.img2} alt="Image2" /></div>
                    </div>
                </div>
            </div>
        </motion.div>
    </>
  )
}

export default SkillBox