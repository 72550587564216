import React from 'react'
import './Footer.css'
import Skill from '../Skill/Skill'
import Cafe from '../../Images/Cafe.png'
import Hearth from '../../Images/Hearth.png'

function Footer() {

  return (
    <>
      <div className='Footer'>
        <div className='footer-holder'>
          <div className='zero-icon'>
            <img src="" alt="" />
          </div>
          <div className='footer-info'>
            <p className='heading'>Thanks for visiting my website</p>
            <p>Created with<Skill Image={Cafe}></Skill> and <Skill Image={Hearth}></Skill> by AliJenesyx</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer