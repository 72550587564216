import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAS4xNaNvj9sme8z_RRmyRRf9KYOjT3enI",
    authDomain: "aliwebsite-718c7.firebaseapp.com",
    databaseURL: "https://aliwebsite-718c7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "aliwebsite-718c7",
    storageBucket: "aliwebsite-718c7.appspot.com",
    messagingSenderId: "767168343847",
    appId: "1:767168343847:web:6adee7fef0fce2bfac0302",
    measurementId: "G-6ELC45SNTV"
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)