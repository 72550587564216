import React from 'react'
import './SocialPage.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import SocialMove from '../../components/SocialMove/SocialMove'
import DisThin from '../../Images/Dis-Thin.png'
import InstaThin from '../../Images/Insta-Thin.png'
import TwiThin from '../../Images/Twi-Thin.png'
import GitThin from '../../Images/Git-Thin.png'
import DisSolid from '../../Images/Dis-Solid1.png'
import InstaSolid from '../../Images/Insta-Solid.png'
import TwiSolid from '../../Images/Twi-Solid.png'
import GitSolid from '../../Images/Git-Solid.png'
import DisText from '../../Images/Dis-Text.png'
import InstaText from '../../Images/Insta-Text.png'
import TwiText from '../../Images/Twi-Text.png'
import GitText from '../../Images/Git-Text.png'
import MobileSocial from '../MobileSocial/MobileSocial'
import MobileFooter from '../../components/MobileFooter/MobileFooter'


function SocialPage() { 

    const openLink = (url) => {
        window.open(url, '_blank');
    }

  return (
    <>
        <div className="sball1 sbg-shadow"></div>
        <div className="sball2 sbg-shadow"></div>
        <div className="sball3 sbg-shadow"></div>
        <div className='Header--controller'>
            <Header showWelcomeMessage = {false}/>
        </div>
        <section className='social-counter'>  
            <div>
                <SocialMove thin={DisThin} solid={DisSolid} text={DisText} onClick={() => openLink('https://discord.gg/6w7ADu7bsB')} />
                <SocialMove thin={InstaThin} solid={InstaSolid} text={InstaText} onClick={() => openLink('https://www.instagram.com/alijenesyx/')} />
                <SocialMove thin={TwiThin} solid={TwiSolid} text={TwiText} onClick={() => openLink('https://twitter.com/Jenesyx')} /> 
                <SocialMove thin={GitThin} solid={GitSolid} text={GitText} onClick={() => openLink('https://github.com/Jenesyx')} />
            </div>
        </section>
        <div className='this-mobile'>
            <MobileSocial/>
        </div>
        <div className="mf">
            <MobileFooter/>
        </div>
        <div>
            <Footer/>
        </div>
    </>
  )
}

export default SocialPage