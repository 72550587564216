import React from 'react'
import { useState } from 'react';
import './SocialMove.css'

function SocialMove(props) {

  const [isHovered, setIsHovered] = useState(false);
  const [isScale, setIsScale] = useState(false);
  const [isTranslate, setIsisTranslate] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
    setIsScale(!isScale);
    setIsisTranslate(!isTranslate);
  };

  return (
    <div className='Icon-Text-Holder' onMouseEnter={handleHover} onMouseLeave={handleHover}>
         <img className={`Thin ${isHovered ? 'hide' : ''}`} src={props.thin} alt="img"
         />
         <img className={`Solid ${isHovered ? 'show' : 'hide'} ${isScale ? 'highlight' : ''}`} onClick={props.onClick} src={props.solid} alt="img"
         />
         <img className={`Text ${isHovered ? 'show' : 'hide'} ${isTranslate ? 'highlight' : ''}`} src={props.text} alt="img"
         />
    </div>
  )
}

export default SocialMove