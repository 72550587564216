import React from 'react'
import { useState, useEffect } from 'react'
import { getDocs, updateDoc, doc } from 'firebase/firestore'
import { motion } from "framer-motion";
import './HomePage.css'
import Spline from '@splinetool/react-spline';
import { useNavigate } from 'react-router-dom';
import { homepageRef } from '../../firestore.collections';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Statics from '../../components/Statics-Widgets/Static'
import Skill from '../../components/Skill/Skill'
import HTML from '../../Images/html.png'
import after from '../../Images/after-effects.png'
import bootstrap from '../../Images/bootstrap.png'
import C from '../../Images/C.png'
import Csharp from '../../Images/Csharp.png'
import cssicon from '../../Images/cssicon.png'
import figma from '../../Images/figma.png'
import git from '../../Images/git.png'
import github from '../../Images/github.png'
import illustrator from '../../Images/illustrator.png'
import javascript from '../../Images/javascript.png'
import micvccode from '../../Images/micvscode.png'
import npm from '../../Images/npm.png'
import photoshop from '../../Images/photoshop.png'
import premier from '../../Images/premier-pro.png'
import react from '../../Images/react.png'
import sass from '../../Images/sass.png'
import solidity from '../../Images/solidity.png'
import vscode from '../../Images/vscode.png'
import twitch from '../../Images/Twitch.png'
import discord from '../../Images/Discord.png'
import twitter from '../../Images/Twitter.png'
import instagram from '../../Images/Insta.png'
import polygon1 from '../../Images/Polygon1.png'
import polygon2 from '../../Images/Polygon2.png'
import polygon3 from '../../Images/Polygon3.png'
import rightPolygon from '../../Images/right-polygon.png'
import MobileFooter from '../../components/MobileFooter/MobileFooter';
import SecondHeader from '../../components/SecondHeader/SecondHeader';

function HomePage() {
  // Navigations
  const navigate = useNavigate();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [showSecondHeader, setShowSecondHeader] = useState(true);
  const [headerTop, setHeaderTop] = useState(0);
  const [socialRotate, setSocialRotate] = useState(false);
  
  const toSocial = () => {
    navigate('/SocialPage');
  };

  const toSkills = () => {
    navigate('/Skills');
  };


  // Values
  const [homepageStatics, setHomepageStatics] = useState([]);
  const [linesOfCode, setLinesOfCode] = useState(0)
  const [cupsOfCoffee, setCupsOfCoffee] = useState(0);

  useEffect(() => {
    getHomepageData()
  }, [])
  
  // Database
  function getHomepageData() {
    getDocs(homepageRef)
    .then(response => {
      const staticData = response.docs.map(doc => ({
        data: doc.data(),
        id: doc.id
      }))
      setLinesOfCode(staticData[0]?.data.linesOfCode || 0);
      setCupsOfCoffee(staticData[0]?.data.cupsOfCoffee || 0);
      setHomepageStatics(staticData)
    }).catch(error => console.log(`This is the error ${error}`))
  }

  const updateDatabase = async (linesOfCode, cupsOfCoffee, documentId) => {
    const homepageDocRef = doc(homepageRef, documentId);
  
    try {
      await updateDoc(homepageDocRef, {
        linesOfCode: linesOfCode,
        cupsOfCoffee: cupsOfCoffee,
      });
  
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const socialMouseEnter = () => {
    setSocialRotate(!socialRotate)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowWelcomeMessage(scrollY <= 300);

      if (scrollY >= 400) {
        setShowSecondHeader(false);
      } else {
        setShowSecondHeader(true);
      }

      setHeaderTop(scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const newTop = window.scrollY;
      setHeaderTop(newTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  

  // Function to update the statistics smoothly
  const smoothUpdate = (currentValue, targetValue, updateFunction) => {
    const increment = targetValue > currentValue ? 1 : -1;
    const updateInterval = 50;
    const steps = Math.abs(targetValue - currentValue);
    const stepSize = Math.ceil(steps / (1000 / updateInterval));

    const updateStep = () => {
      if ((increment > 0 && currentValue + stepSize >= targetValue) || (increment < 0 && currentValue - stepSize <= targetValue)) {
        updateFunction(targetValue);
      } else {
        updateFunction(prevValue => prevValue + increment * stepSize);
      }
    };

    const intervalId = setInterval(updateStep, updateInterval);

    setTimeout(() => clearInterval(intervalId), Math.abs(targetValue - currentValue) / stepSize * updateInterval + 10);
  };


  //////////////////////////////////////// Function to update the statistics every 5 seconds
  useEffect(() => {
    const updateStatistics = () => {
      const newLinesOfCode = linesOfCode + 250;
      const newCupsOfCoffee = cupsOfCoffee + 2;

      smoothUpdate(linesOfCode, newLinesOfCode, setLinesOfCode);
      smoothUpdate(cupsOfCoffee, newCupsOfCoffee, setCupsOfCoffee);

      if (homepageStatics.length > 0) {
        updateDatabase(newLinesOfCode, newCupsOfCoffee, homepageStatics[0].id);
      }
    };

    // Schedule the updateStatistics function to run every 24 hours (86400000 milliseconds)
    const intervalId = setInterval(updateStatistics, 86400000);

    return () => clearInterval(intervalId);
  }, [linesOfCode, cupsOfCoffee, homepageStatics]);

  // useEffect(() => {
  //   axios.get('https://aliwebsite-718c7-default-rtdb.europe-west1.firebasedatabase.app/')
  //     .then((response) => {
  //       console.log(`This is data : ${data}`)
  //       setData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching "Main" data:', error);
  //     });
  // },[]);

  // axios.post('http://localhost:5000/statistics/update', {
  //   linesOfCode: updatedLinesOfCode,
  //   cupsOfCoffee: updatedCupsOfCoffee 
  // })
  // .then(response => {
  //   console.log('Statistics updated successfully');
  //   // You might also want to update your component's state or UI accordingly
  // })
  // .catch(error => {
  //   console.error('Error updating statistics:', error);
  // });
  // useEffect(() => {
  //   // Make a GET request to the backend endpoint
  //   axios.get('http://localhost:4000/api/main')
  //     .then((response) => {
  //       // Set the data in state when the response is received
  //       setData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  return (
    <>
        <div style={{position: 'relative', top: `${headerTop}px`, width: '100%'}}> 
          {showSecondHeader ? <SecondHeader  showWelcomeMessage={showWelcomeMessage}/> : <Header showWelcomeMessage={showWelcomeMessage}/>}
        </div>
        <div className="ball1 bg-shadow">
          <img src={rightPolygon} alt="" />
        </div>
          <section id='about-me' className='about-me'>
            <div className='title-holder'>
              <motion.h2
                initial={{opacity: 0, y: 50}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.7}}
                viewport={{once: true}}
              >Who is Ali<span>JENESYX</span>?</motion.h2>
            </div>
            <div className='secondary'>
              <div className='text-holder'>
                <motion.p
                  initial={{opacity: 0, y: 50}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                >
                  Ali Bidkhori, also known as Jenesyx, is a 19-year-old creative powerhouse residing in Germany. Originally from Tehran, Iran, he moved to Germany six years ago. At the age of 16, he discovered his passion for design and programming, teaching himself Photoshop, Illustrator, Premier Pro, After Effects, Figma, and programming. Currently pursuing a college education in IT, he specializes in website development and is a skilled Solidity Developer. Additionally, Ali is an avid NFT and crypto trader with a profound interest in Web3. Beyond his technical pursuits, he has a history of being a professional FPS gamer. Ali's journey is marked by unwavering curiosity and a relentless drive to explore the intersections of creativity and technology. But Ali's journey doesn't end there. He is also an accomplished professional swimmer, gliding through the water with grace and prowess. Moreover, he serves as a dedicated lifeguard, ensuring the safety of others while cherishing the connection with the aquatic world. Recognizing the importance of overall health, Ali diligently trains to keep himself in peak condition, sculpting not just his creative mind but also his physical form. A true embodiment of discipline and ambition.
                </motion.p>
              </div>
              <motion.div className='images-holder'
                  initial={{opacity: 0, y: 50}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                >
                <div className='clear'> 
                  <div className='image-cover one'><img src={polygon1} alt="" /></div>
                  <div className='image-cover two'><img src={polygon2} alt="" /></div>
                  <div className='image-cover three'><img src={polygon3} alt="" /></div>
                </div>
              </motion.div>
            </div>
          </section>
          <div className="ball2 bg-shadow"></div>
          <section className='Statics'>
            <motion.h2
              initial={{opacity: 0, y: 60}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >Statistics</motion.h2>
            <div className='static--holder'>
              <Statics scene="https://prod.spline.design/6qoJJbf-dmjDzoHI/scene.splinecode" number={linesOfCode} categorie="Lines of Code"/>
              <Statics scene="https://prod.spline.design/6XUNoa7xuIUosmZ6/scene.splinecode" number="444" categorie="Number of Arts"/>
              <Statics scene="https://prod.spline.design/GCJyOWbES3j025X7/scene.splinecode" number={cupsOfCoffee} categorie="Cups of Coffee"/>
              <Statics scene="https://prod.spline.design/PSc0m9MuBrbmg3U5/scene.splinecode" number="46" categorie="Successful Project"/>
            </div>
          </section>
          <div className="ball3 bg-shadow"></div>
          {/* Skills */}
          <section className='Skills'>
            <motion.h2
              initial={{opacity: 0, y: 60}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >Skills</motion.h2>
            <div className='skills--holder'>
              <div className='skill-left'>
                <motion.div className='skill--title'
                  initial={{opacity: 0, y: 50}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                >
                  <p>Everything started with</p><div><img src={HTML} alt="HTML-Icon"/></div><p>and so on ...</p>
                </motion.div>
                <motion.div className='skill--short'
                  initial={{opacity: 0, y: 90}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                >
                  <p>
                    I learned everything by myself, and all I had was interest. Although I don't have time to move them all together, I always keep myself up-to-date.
                  </p>
                </motion.div>
                <div className='skill--desc'>
                  <motion.div className='skill--position'
                  initial={{opacity: 0, y: 80}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                  >
                    <div className='skill--circle'></div>
                    <p>Current position</p>
                  </motion.div>
                  <motion.div className='skill--position--subset'
                    initial={{opacity: 0, y: 90}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.7}}
                    viewport={{once: true}}
                  >
                    <div className='skill--line'></div>
                    <ul>
                      <li><p>Front-end Developer</p></li>
                      <li><p>Smart Contracts (Solidity)</p></li>
                      <li><p>UI | UX Designer</p></li>
                      <li><p>Graphic Designer</p></li>
                    </ul>
                  </motion.div>
                </div>
              </div>
              <div className='skill-right'>
                <motion.div className='skills--box'
                  initial={{opacity: 0, y: 100}}
                  whileInView={{opacity: 1, y: 0}}
                  transition={{duration: 0.7}}
                  viewport={{once: true}}
                >
                  <Skill style={{background:"red"}} className='icon--rotate' Image={cssicon}/>
                  <Skill Image={vscode}/>
                  <Skill className='icon--rotate' Image={solidity}/>
                  <Skill Image={sass}/>
                  <Skill className='icon--rotate' Image={javascript}/>
                  <Skill Image={react}/>
                  <Skill Image={npm}/>
                  <Skill className='icon--rotate' Image={micvccode}/>
                  <Skill className='icon--rotate' Image={Csharp}/>
                  <Skill Image={github}/>
                  <Skill Image={figma}/>
                  <Skill Image={git}/>
                  <Skill className='icon--rotate' Image={illustrator}/>
                  <Skill className='icon--rotate' Image={after}/>
                  <Skill Image={bootstrap}/>
                  <Skill Image={C}/>
                  <Skill className='icon--rotate' Image={premier}/>
                  <Skill Image={photoshop}/>
                </motion.div>
              </div>
            </div>
          </section>
          <div className="ball4 bg-shadow"></div>
          {/* piece Word */}
          <section className='Piece--word'>
            <motion.div
              initial={{opacity: 0, y: 90}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.7}}
              viewport={{once: true}}
            >
              <h2>
                Your <span>time</span> is <span>limited</span>,<br/>
                so don’t waste it living someone else’s <span>life</span>
              </h2>
            </motion.div>
          </section>
          {/* Project */}
          <motion.section className='Projects'
            initial={{opacity: 0, y: 100}}
            whileInView={{opacity: 1, y: 0}}
            transition={{duration: 0.9}}
            viewport={{once: true}}
          >
            <motion.h2
              initial={{opacity: 0, y: 60}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.6}}
              viewport={{once: true}}
            >Projects</motion.h2>
            <div className='projects-holder'>
              <div className="p--left">
                <p className='p--title'>Visit some of my projects!</p>
                <p className='p--second-title'>Explore a selection of projects I've passionately crafted, each one a solo endeavor showcasing my dedication and expertise. Feel free to contact me!!</p>
              </div>
              <div className="p--right">
                <div className="p--btn" onClick={toSkills}>Let's See!</div>
              </div>
            </div>
          </motion.section>
          <section className='Social-Media'>
            <motion.h2
              initial={{opacity: 0, y: 60}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.8}}
              viewport={{once: true}}
            >Socialmedia</motion.h2>
            <motion.div className='social--holder'
              initial={{opacity: 0, y: 90}}
              whileInView={{opacity: 1, y: 0}}
              transition={{duration: 0.7}}
              viewport={{once: true}}
            >
              <div className='icons--holder'>
                <div className={`s--twitch ${socialRotate ? 'rotate' : ''}`}>
                  <Skill Image={twitch}></Skill>
                </div>
                <div className={`s--discord ${socialRotate ? 'rotate' : ''}`}>
                  <Skill Image={discord}></Skill>
                </div>
                <div className={`s--twitter ${socialRotate ? 'rotate' : ''}`}>
                  <Skill Image={twitter}></Skill>
                </div>
                <div className={`s--instagram ${socialRotate ? 'rotate' : ''}`}>
                  <Skill Image={instagram}></Skill>
                </div>
              </div>
              <div className='social--box'>
                <p>Follow me for more content !!!</p>
                <div className='s--github'>
                  <Skill Image={github}/>
                </div>
                <button onClick={toSocial} onMouseEnter={socialMouseEnter} onMouseLeave={socialMouseEnter}>GO !</button>
              </div>
            </motion.div>
          </section>
        <div className="ball5 bg-shadow"></div>
        <div className="mf">
          <MobileFooter/>
        </div>
        <div>
          <Footer/>
        </div>
    </>
  )
}

export default HomePage