import React from 'react'
import { TypeAnimation } from 'react-type-animation';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"; 
import Homered from '../../Images/Homered.png'
import Userblue from '../../Images/Userblue.png'
import ProjectYellow from '../../Images/ProjectYellow.png'
import Starpurple from '../../Images/Starpurple1.png'
import Storegreen from '../../Images/Storegreen.png'
import './SecondHeader.css'

function SecondHeader({showWelcomeMessage = true}) {

  const location = useLocation();
  const navigate = useNavigate();

  const toHome = () => {
    navigate('/')
  }

  const toSocial = () => {
    navigate('/SocialPage');
  };

  const toSkills = () => {
    navigate('/Skills');
  };

  const toStore = () => {
    navigate('/Store');
  };

  const toAbout = () => {
    if (location.pathname === '/') {
      const aboutMeSection = document.getElementById('about-me');
      if (aboutMeSection) {
        const yOffset = -100;
        const y = aboutMeSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      navigate('/');
    }
  };

  return (
    <>
        <nav>
        <header>
            <div className="header">
                <ul>
                    <li onClick={toHome}>
                        <div className="h-img home">
                            <img src={Homered} alt="Home icon" />
                        </div>
                        <p>Home</p>
                    </li>
                    <li onClick={toAbout}>
                        <div className="h-img user">
                            <img src={Userblue} alt="User icon" />
                        </div>
                        <p>About me</p>
                    </li>
                    <li onClick={toSkills}>
                        <div className="h-img project">
                            <img src={ProjectYellow} alt="Projects icon" />
                        </div>
                        <p>Projects</p>
                    </li>
                    <li onClick={toSocial}>
                        <div className="h-img star">
                            <img src={Starpurple} alt="Star icon" />
                        </div>
                        <p>Social media</p>
                    </li>
                    <li onClick={toStore}>
                        <div className="h-img store">
                            <img src={Storegreen} alt="Store icon" />
                        </div>
                        <p>Store</p>
                    </li>
                </ul>
            </div>
            </header>
            {showWelcomeMessage && (
                <motion.div className='welcomer'
                initial={{opacity: 0, y: 120}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.7}}
                viewport={{once: true}}
                >
                <h1>
                Welcome to my little&nbsp;
                <TypeAnimation
                sequence={[
                  'Website',
                  1000,
                  'Life',
                  1000
                ]}
                speed={40}
                repeat={Infinity}
              />
                </h1>
                </motion.div>
            )}
        </nav>
    </>
)}

export default SecondHeader