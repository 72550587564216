import React from 'react'
import './Store.css'
import Header from '../../components/Header/Header'
import ProductCard from '../../components/ProductCard/ProductCard'
import Working from '../Working/Working'
import productsData  from '../../Data/productsData'
import Footer from '../../components/Footer/Footer'
import MobileFooter from '../../components/MobileFooter/MobileFooter'

function Store() {
  
  return (
    <> 
      {/* <div>
        <Working/>
      </div> */}
        <div className='Header--controller'>
            <Header showWelcomeMessage = {false}/>
        </div>
        <div className="store-title">
            <p><span>AJ</span> Store!</p>
        </div>
        <section className='product-cards'>
        {Object.values(productsData).map((product) => {
          return <ProductCard key={product.Id} Id={product.Id} {...product} />;
        })}
        </section>
        <div className="mf">
          <MobileFooter/>
        </div>
        <div>
          <Footer/>
        </div>
    </>
  )
}

export default Store