import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import Star from '../../Images/Star.png'
import User from '../../Images/User_01.png'
import Home from '../../Images/House_01.png'
import Document from '../../Images/File_Document.png'
import './Header.css'


function Header({showWelcomeMessage = true}) {

  const [isLeftHighlighted, setLeftIsHighlighted] = useState(false);
  const [isRightHighlighted, setRightIsHighlighted] = useState(false);
  const location = useLocation();

  const handleIslandClick = () => {
    setLeftIsHighlighted(!isLeftHighlighted);
    setRightIsHighlighted(!isRightHighlighted);
  }

  const navigate = useNavigate();

  const toHome = () => {
    navigate('/')
  }

  const toSocial = () => {
    navigate('/SocialPage');
  };

  const toSkills = () => {
    navigate('/Skills');
  };
 
  const toAbout = () => {
    if (location.pathname === '/') {
      const aboutMeSection = document.getElementById('about-me');
      if (aboutMeSection) {
        const yOffset = -100;
        const y = aboutMeSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <nav>
        <header>
          <div className={`left--side bubble-holder ${isLeftHighlighted ? 'highlight' : ''}`}>
            <div className='social-media bubbles'>
              <img src={Star} onClick={toSocial} alt="star" />
            </div>  
            <div className='about-me bubbles'>
              <img src={User} onClick={toAbout} alt="User" />
            </div>
          </div>
          <div onClick={handleIslandClick} className='island c-blue'>Menu</div>
          <div className={`right--side bubble-holder ${isRightHighlighted ? 'highlight' : ''}`}>
            <div className='home bubbles'>
              <img src={Home} onClick={toHome} alt="Home" />
            </div>
            <div className='portfolio bubbles'>
              <img src={Document} onClick={toSkills} alt="Document" />
            </div>
          </div>
        </header>
        {showWelcomeMessage && (
          <motion.div className='welcomer'
            initial={{opacity: 0, y: 120}}
            whileInView={{opacity: 1, y: 0}}
            transition={{duration: 0.7}}
            viewport={{once: true}}
          >
            <h1>Welcome to my little <span>W</span>ebsite!</h1>
          </motion.div>
        )}
      </nav>
    </>
  )
}

export default Header