import React from 'react'
import './Skill.css'

function Skill(props) {
  return (
    <div className='Skill-image-holder'>
        <img src={props.Image} alt="Skills-Images"/>
    </div>
  )
}

export default Skill