import React from 'react'
import Spline from '@splinetool/react-spline';
import './PlayGround.css'

function PlayGround() {
  return (
    <div className='pg1'>
        <Spline scene="https://prod.spline.design/vJ5PNLv1MjNhgmDf/scene.splinecode"/>
    </div>
  )
}

export default PlayGround