import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Star from '../../Images/Star.png'
import User from '../../Images/User_01.png'
import Home from '../../Images/House_01.png'
import Document from '../../Images/File_Document.png'
import Store from '../../Images/Store_01.png'
import { motion } from "framer-motion";
import './MobileFooter.css'


function MobileFooter() {

    const location = useLocation();
    const navigate = useNavigate();

    const toHome = () => {
        navigate('/')
    };

    const toSocial = () => {
        navigate('/SocialPage');
    };

    const toSkills = () => {
        navigate('/Skills');
    };

    const toStore = () => [
        navigate('/Store')
    ]

    const toAbout = () => {
        if (location.pathname === '/') {
            const aboutMeSection = document.getElementById('about-me');
            if (aboutMeSection) {
            const yOffset = -100;
            const y = aboutMeSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            }
        } else {
            navigate('/');
        }
    };

    return (
        <>
            <motion.div className='mobile--footer'
                initial={{opacity: 0, y: 50}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.7}}
                viewport={{once: true}}
            >
                <ul>
                    <li><img src={Star} onClick={toSocial} alt="Star" /></li>
                    <li><img src={User} onClick={toAbout} alt="User" /></li>
                    <li><img src={Home} onClick={toHome} alt="Home" /></li>
                    <li><img src={Document} onClick={toSkills} alt="Document" /></li>
                    <li><img src={Store} onClick={toStore} alt="Store" /></li>
                </ul>
            </motion.div>
        </>
    )
}

export default MobileFooter