import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import HomePage from './Pages/HomePage/HomePage';
import SocialPage from './Pages/Socialmedia/SocialPage';
import SkillPage from './Pages/SkillsPage/SkillPage';
import MobileSocial from './Pages/MobileSocial/MobileSocial';
import GG from './Pages/GG/GG'
import ScrollToTop from './components/ScrollToTop';
import Store from './Pages/Store/Store';
import Product from './Pages/Product/Product';
import PlayGround from './Pages/PlayGround/PlayGround';
import Loading from './components/Loading/Loading';
import ClothesProduct from './Pages/ClothesProduct/ClothesProduct';


function App() {

  // const [loading, setLoading] = useState(window.innerWidth > 1000);

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth > 1000) {
  //       setLoading(true);
  //     } else {
  //       setLoading(false);
  //     }
  //   }

  //   window.addEventListener('resize', handleResize);

  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 4000);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     clearTimeout(timer);
  //   };
  // }, []);
  
  return (
    <div className='App'>
    {/* {loading && <Loading />} */}
      <Router>
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/socialpage" element={<SocialPage/>} />
          <Route path="/skills" element={<SkillPage/>} /> 
          <Route path="/extra" element={<MobileSocial/>} />
          <Route path="/store" element={<Store />} />
          <Route path="/Fact1" element={<GG />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/product/:productId" element={<Product/> } />
          <Route path="/ClothesProduct/:productId" element={<ClothesProduct/>} />
          <Route path="/playground" element={<PlayGround/>} />
          <Route path="/Loading" element={<Loading/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;