import React, { useEffect, useState } from 'react'
import './Product.css'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { app } from '../../config'
import { getDatabase, ref, set, push } from 'firebase/database' 
import Header from '../../components/Header/Header';
import productsData  from '../../Data/productsData'
import chevronDown from '../../Images/Chevron_Down.png'
import Footer from '../../components/Footer/Footer';
import Storewhite from '../../Images/Storewhite.png'
import { motion } from "framer-motion";
import MobileFooter from '../../components/MobileFooter/MobileFooter';


function Product() {

    let { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [functionDesc, setFunctionDesc] = useState('');
    const [showF, setShowF] = useState(false)
    const [showM, setShowM] = useState(false)
    const [showA, setShowA] = useState(false)
    const [errors, setErrors] = useState({});
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [selectedFunction, setSelectedFunction] = useState('');
    const [userDesc, setUserDesc] = useState('')
    const [hideShip, setHideShip] = useState('false')
    const navigate = useNavigate();

    const toStore = () => {
        navigate('/Store')
    }
    
    
    const validate = () => {
        let tempErrors = {};
        tempErrors.username = userName ? "" : "This field is required.";
        tempErrors.email = userEmail ? (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail) ? "" : "Email is not valid.") : "This field is required.";
        tempErrors.phone = userPhone ? (/^\+?[0-9]{10,15}$/g.test(userPhone) ? "" : "Phone number is not valid.") : "This field is required.";
        tempErrors.functionName = selectedFunction ? "" : "Please select a functionality.";
        tempErrors.userDesc = userDesc ? "" : "Please enter a description.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const saveData = async () => {
        if (validate()) {
            const db = getDatabase(app)
            const newDocRef = push(ref(db, "Orders/RFIDCard"))
            set(newDocRef, {
                username: userName,
                email: userEmail,
                phone: userPhone,
                functionName: selectedFunction,
                userDesc: userDesc
            }).then(() => {
                alert("Your order has been registered! We will contact your soon as possible!")
            }).catch((err) => {
                alert("Error: ", err.message)
            })
        } else {
            alert("Please fill in all fields correctly.");
        }
    };

    const handleShowF = () => {
        setShowF(!showF)
        setShowM(!showM)
        setShowA(!showA)
    }

    const handleSelect = (func, event) => {
        event.stopPropagation();
        setSelectedFunction(func.name); 
        setFunctionDesc(func.desc);
        setShowF(false);
        setShowM(false)
        setShowA(false)
    };

    const hanldeHidden = () => {
        setHideShip(!hideShip)
    }

    useEffect(() => {
        const productData = productsData[productId];
        if (productData) {
            setProduct(productData);
        } else {
            console.error(`Product with ID ${productId} not found.`);
        }
    }, [productId]);
    
    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className='Header--controller'>
                <Header showWelcomeMessage = {false}/>
            </div>
            <motion.div className="store-title"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <p><span>//// &nbsp;&nbsp;</span>{product.Title}</p>
            </motion.div>
            <div className="product-information">
                <div className="set-info">
                    <motion.div className="personalinfo"
                        initial={{opacity: 0, y: 60}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.8}}
                        viewport={{once: true}}
                    >
                        <div className="product-title"><span>Your</span> Personal Data</div>
                        <input type="text" placeholder='Your Fullname' onChange={(e) => setUserName(e.target.value)}/>
                        <input type="email" placeholder='Your Email' onChange={(e) => setUserEmail(e.target.value)}/>
                        <input type="tel" placeholder='Your Phone number' onChange={(e) => setUserPhone(e.target.value)}/>
                    </motion.div>
                    <motion.div className="functionality"
                        initial={{opacity: 0, y: 60}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.8}}
                        viewport={{once: true}}
                    >
                        <div className="product-title"><span>F</span>unctionality</div>
                        <div className={`func-dropdown ${showM ? 'show' : ''}`} onClick={handleShowF}>
                            <div className="dropdownmenu">    
                                {selectedFunction || 'Choose ...'}
                                {product.Functions.map((func, index) => (
                                    <a className={`everyf ${showF ? 'show' : 'hidethis'}`} key={index} onClick={(e) => handleSelect(func, e)} href="#"><p>{func.name}</p></a>
                                ))}
                            </div>
                            <div className={`arrow ${showA ? 'show' : ''}`}><img src={chevronDown} alt="Arron Down" /></div>
                        </div>
                    </motion.div>
                    <motion.div className="user-desc"
                        initial={{opacity: 0, y: 60}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.8}}
                        viewport={{once: true}}
                    >
                        <div className="product-title"><span>Your</span> description</div>
                        <div className="text-holder">
                            <textarea maxLength="1000" onChange={(e) => setUserDesc(e.target.value)}/> 
                        </div>
                    </motion.div>
                </div>
                <div className="to-store" onClick={toStore}>
                    <div className="store-img-holder">
                        <img src={Storewhite} alt="store-icon" />
                    </div>
                    <p>Store</p>
                </div>
                <motion.div className="functionality-details"
                    initial={{opacity: 0, y: 60}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.8}}
                    viewport={{once: true}}
                >
                    <div className="product-title"><span>F</span>unctionality description</div>
                    <p className='product-desc'>{functionDesc}</p>
                    <div className="send-btn" onClick={saveData}>
                        <p>Send!</p>
                    </div>
                    <div className="error-messages">
                        {Object.keys(errors).map((key) => {
                        if (errors[key]) {
                            return <p key={key} style={{ color: 'red' }}>{errors[key]}</p>;
                        }
                        return null;
                        })}
                    </div>
                </motion.div>
            </div>
            <motion.div className="product-details product-details-margin"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <div className="product-title-last product-title"><span>Unlock</span>  the Future with Customizable RFID Cards</div>
                <p>In today's digital world, making a lasting impression is more important than ever. Traditional business cards are easily lost and forgotten, but imagine handing someone your card, and with just a tap, all your professional details magically appear on their phone. That's not just impressive; it's revolutionary. Welcome to the world of RFID Cards – your new networking powerhouse.</p>
            </motion.div>
            <motion.div className="product-details"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <div className="product-title-last product-title"><span>Why</span> RFID Cards?</div>
                <p>RFID cards are the next step in the evolution of networking. They bridge the gap between the physical and digital, allowing for seamless interaction with smartphones and other RFID-enabled devices. In an instant, your potential clients or partners can access your website, portfolio, social media, or contact details with a simple tap. No more typing in URLs or searching through emails. It's networking at the speed of light.<br/><br/>But it's not just about convenience. It's about making a statement. In a sea of standard business cards, an RFID card stands out. It's a conversation starter, a memorable interaction, and a reflection of your commitment to innovation and technology.</p>
            </motion.div>
            <motion.div className="product-details"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <div className="product-title-last product-title"><span>Why</span> Choose Our RFID Cards?</div>
                <p><span className='u-ours'>Customization:</span><br/>Your brand is unique, and your RFID card should be too. We offer complete customization options to ensure your card perfectly represents your brand identity. From sleek and professional to vibrant and creative, your card will be as unique as you are.</p>
                <p><span className='u-ours'>Speed:</span><br/>In the fast-paced business world, time is of the essence. That's why we pride ourselves on our quick turnaround times. You won't be left waiting; we'll get your RFID cards to you fast, so you can start making those crucial connections.</p>
                <p><span className='u-ours'>Design:</span><br/>A great product needs great design, and that's where we excel. Our team of designers will work with you to create a card that not only looks fantastic but also integrates seamlessly with your branding and business needs.</p>                
            </motion.div>
            <motion.div className="product-details"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <div className="product-title-last product-title"><span>The</span> Smart Choice for Smart Professionals</div>
                <p>RFID cards are more than just a business card; they're a tool for the modern professional. They streamline the way you connect, ensuring that your contact information isn't just received but retained. In a digital age where attention is fleeting, an RFID card ensures you're remembered.<br/><br/>Choose to stand out. Choose efficiency. Choose the future of networking with our customizable RFID cards. Let's redefine how the world connects, one tap at a time.</p>
            </motion.div>
            <div className="c--space"></div>
            <div className="mf">
                <MobileFooter/>
            </div>
            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Product