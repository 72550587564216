import React, { useEffect, useState } from 'react'
import './ClothesProduct.css'
import Header from '../../components/Header/Header'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { app } from '../../config'
import { getDatabase, ref, set, push } from 'firebase/database' 
import productsData from '../../Data/productsData';
import Footer from '../../components/Footer/Footer';
import Storewhite from '../../Images/Storewhite.png'
import MobileFooter from '../../components/MobileFooter/MobileFooter';
import { motion } from "framer-motion";


function ClothesProduct() {
    let { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('')
    const [color, setColor] = useState('')
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const toStore = () => {
        navigate('/Store')
    }

    const selectColor = (colorOption) => {
        setSelectedColor(colorOption);
        setColor(colorOption.name)
    };

    const selectSize = (size) => {
        setSelectedSize(size);
    };

    useEffect(() => {
        const productData = productsData[productId];
        if (productData) {
            setProduct(productData);
        } else {
            console.error(`Product with ID ${productId} not found.`);
        }
    }, [productId]);

    if (!product) {
        return <div>Loading...</div>;
    }

    const validate = () => {
        let tempErrors = {};
        tempErrors.username = userName ? "" : "The username is required.";
        tempErrors.email = userEmail ? (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail) ? "" : "Email is not valid.") : "The email is required.";
        tempErrors.color = color ? "" : "Please select a Color.";
        tempErrors.size = selectedSize ? "" : "Please select a Size.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const saveData = async () => {
        if (validate()) {
            const db = getDatabase(app)
            const newDocRef = push(ref(db, "Orders/Clothes"))
            set(newDocRef, {
                username: userName,
                email: userEmail,
                color: color,
                size: selectedSize
            }).then(() => {
                alert("Your order has been registered! We will contact your soon as possible!")
            }).catch((err) => {
                alert("Error: ", err.message)
            })
        } else {
            alert("Please fill in all fields correctly.");
        }
    };

    return (
        <>
            <div className='Header--controller'>
                <Header showWelcomeMessage = {false}/>
            </div>
            <motion.div className="store-title"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.8}}
                viewport={{once: true}}
            >
                <p><span>{product.Title1}&nbsp;&nbsp;</span>{product.Title2}</p>
            </motion.div>
            <div className="c--product-info">
                <motion.div className="product-info"
                    initial={{opacity: 0, y: 60}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.4}}
                    viewport={{once: true}}
                >
                    <p>
                        {product.Info}
                    </p>
                </motion.div>
                <motion.div className="order-select-one"
                    initial={{opacity: 0, y: 60}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 0.8}}
                    viewport={{once: true}}
                >
                    <input type="text" placeholder='Your Fullname' onChange={(e) => setUserName(e.target.value)}/>
                    <div className="select-color">
                        <p>Color</p>
                        <div className="colors">
                        {product.ColorOptions.map((colorOption, index) => (
                            <div key={index} className={`color-holder ${selectedColor.hex === colorOption.hex ? 'selected' : ''}`} onClick={() => selectColor(colorOption)}>
                                <div 
                                    className="color" 
                                    style={{ backgroundColor: colorOption.hex }} 
                                />
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="c--error-messages">
                        {Object.keys(errors).map((key) => {
                        if (errors[key]) {
                            return <p key={key} style={{ color: 'red' }}>{errors[key]}</p>;
                        }
                        return null;
                        })}
                    </div>
                </motion.div>
                <motion.div className="order-select-two"
                    initial={{opacity: 0, y: 60}}
                    whileInView={{opacity: 1, y: 0}}
                    transition={{duration: 1}}
                    viewport={{once: true}}
                >
                    <input type="text" placeholder='Your Email' onChange={(e) => setUserEmail(e.target.value)}/>
                    <div className="select-size">
                        <p>Size</p>
                        <div className="sizes">
                        {product.SizeOptions.map((size, index) => (
                            <div 
                                className={`size ${selectedSize === size ? 'selected' : ''}`} 
                                key={index} 
                                onClick={() => selectSize(size)}
                            >
                                {size}
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="submit-order">
                        <p className='p-price'>{product.Price}$</p>
                        <div className="order-btn" onClick={saveData}><p>Order!</p></div>
                    </div>
                </motion.div>
            </div>
            <div className="to-store" onClick={toStore}>
                <div className="store-img-holder">
                    <img src={Storewhite} alt="store-icon" />
                </div>
                <p>Store</p>
            </div>
            <motion.div className="product-images"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 1.1}}
                viewport={{once: true}}
            >
                <div className="p-img-holder">
                    <img src={product.Image1} alt="Tshirt1" />
                </div>
                <div className="p-img-holder">
                    <img src={product.Image2} alt="Tshirt3" />
                </div>
                <div className="p-img-holder">
                    <img src={product.Image3} alt="Tshirt2" />
                </div>
            </motion.div>
            <motion.div className="good-to-know"
                initial={{opacity: 0, y: 60}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 1}}
                viewport={{once: true}}
            >
                <p className="gtk-title">Good to <span>Know!</span></p>
                <div className="c--product-details">
                    <div className="c--product-title"><span className='span-green'>H</span>ow to order?</div>
                    <p>You just need to fill in the required information and send it to us. We will try to contact you as soon as possible to complete the order. We will ask you the model and type of your T-shirt and after that we will produce it and send it to your address.</p>
                </div>
                <div className="c--product-details">
                    <div className="c--product-title"><span className='span-green'>S</span>hipping</div>
                    <p>Once your order is finalized, please allow up to 7 days for dispatch via German Post. The postage costs will be borne by you. We offer shipping across the entirety of Germany.</p>
                </div>
                <div className="c--product-details">
                    <div className="c--product-title"><span className='span-green'>L</span>imitation</div>
                    <p>To maintain its uniqueness, we've set a limit!</p>
                </div>
                <div className="c--product-details">
                    <div className="c--product-title"><span className='span-green'>P</span>roduct Details</div>
                    <p>For this T-shirt, we've curated two exceptional materials, each surpassing the next in quality. Once you place your order and select your preferred T-shirt, we will provide detailed information about your choice. Your feedback is invaluable to us and will be taken into consideration.</p>
                </div>
            </motion.div>
            <div className="c--space"></div>
            <div className="mf">
                <MobileFooter/>
            </div>
            <div>
                <Footer/>
            </div>
        </>
    )
}

export default ClothesProduct